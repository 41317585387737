<template>
  <div class="app-context expanded">
    <div class="context complex">
      <div class="c-context">
        <v-container>
          <v-row>
            <v-col cols="12">
              <directive :directiveInfo="directiveInfo"></directive>
            </v-col>
          </v-row>
        </v-container>

        <div class="join document">
          <div class="info info-content pr-2 pl-2">
            <div class="article">
              <h5>
                단, 아래 항목은 표기된 법률에 따라 특정기간 동안 보관됩니다.
              </h5>
              <p>
                <span>1.</span> DrRefer가 수집 · 보유한 이용자의
                개인(신용)정보는 회원 탈퇴 시 지체없이 파기함을 원칙으로 합니다.
              </p>
              <p>
                <span>2.</span> 단, 사고 조사, 분쟁 해결, 민원처리, 법률상
                의무이행만을 위하여 일부 개인(신용)정보는 보유 · 이용될 수
                있습니다.
              </p>
              <p>
                <span>3.</span> 온라인 거래 관련 개인(신용)정보는 전자 금융
                거래법 시행령 제 12조에서 정하는 기간까지 보유 · 이용됩니다.
              </p>
            </div>
            <div class="article">
              <h5>파기방법</h5>
              <p>
                <span>1.</span> 개인정보가 기록된 출력물ㆍ서면 등을 파쇄 또는
                소각
              </p>
              <p>
                <span>2.</span> 전자적 파일형태의 개인정보: 복원이 불가능한
                방법으로 영구삭제
              </p>
            </div>
          </div>
          <!--
          <div class="document-btn-wrap">
            <button type="button" class="draw" @click="draw()">
              탈퇴
            </button>
          </div>
          -->
          <div class="document-btn-wrap">
            <div class="item iconIn delete" @click="draw()">
              <img src="@/assets/images/icons/logout.svg" />
              <!-- <span class="material-icons-outlined google-icon">logout</span> -->
              <!-- <img src="@/assets/images/menuIcon/draw.png" /> -->
              <p>탈퇴</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <default-popup-side
      v-if="checkPopup"
      :popupSet="popupSet"
      @call-withdraw="withDraw"
    />
    <default-popup v-if="checkPopup2" :popupSet="popupSet" />
  </div>
</template>

<script>
import Directive from "@/components/join/Directive";
import { mapMutations, mapState, mapActions } from "vuex";
import DefaultPopupSide from "../../modal/DefaultPopupSide.vue";
import DefaultPopup from "../../modal/DefaultPopup.vue";
export default {
  components: {
    Directive,
    DefaultPopupSide,
    DefaultPopup,
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },
      popupSet: {},
      directiveInfo: {
        title: "회원정보 탈퇴",
        isTitleNewLine: false,
        content:
          "회원탈퇴 처리 후에는 회원님의 개인정보를 복원할 수 없으며,\n 탈퇴 진행 시 해당 아이디는 영구적으로 삭제되어 재가입이 불가합니다.",
        isContentNewLine: true,
        warn: null,
        isWarnNewLine: false,
      },
      sideSet: {
        menuName: "mypage",
        sideTitle: "마이페이지",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
    };
  },
  created() {
    window.scrollTo(0, 0);
    // if (history.pushState !== undefined) {
    //   history.pushState({}, "", location.href);
    // }
  },
  mounted() {
    // document.querySelector(".draw").classList.add("active");
  },
  computed: {
    ...mapState("basic", {
      checkPopup: (state) => state.side.checkPopup,
      checkPopup2: (state) => state.checkPopup,
    }),
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP_SIDE", "SET_POPUP"]),
    ...mapActions("auth", ["WITH_DRAW"]),
    draw() {
      this.SET_POPUP_SIDE(true);
      this.popupSet.title = "회원탈퇴";
      this.popupSet.content =
        "회원 탈퇴를 원하시면 '탈퇴'를 선택해주세요.\n 한번 탈퇴된 정보는 복구되지 않으니 신중히 결정해 주시기 바랍니다.";
      this.popupSet.popType = "withDraw";
      this.popupSet.confirmBtnText = "탈퇴";
      this.popupSet.cancelBtnText = "취소";
    },
    async withDraw() {
      try {
        await this.WITH_DRAW().then(() => {
          const sendAppheaderItem = {
            method: "clearItem",
          };
          this.$emit("send-app-header-method", sendAppheaderItem);
          this.SET_POPUP(true);
          this.popupSet.title = "탈퇴완료";
          this.popupSet.content = "회원탈퇴가 성공적으로 진행되었습니다.";
          this.popupSet.cancelBtnText = "확인";
          this.popupSet.popType = "drawwarn";
        });
      } catch (error) {
        console.log("error:", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "서버통신장애로 탈퇴하지 못했습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    clearLocalStorage() {
      localStorage.removeItem("drId");
      localStorage.removeItem("nm");
      localStorage.removeItem("token");
      localStorage.removeItem("memType");
      localStorage.removeItem("pwActiveYn");
      localStorage.removeItem("pwElapYn");
      localStorage.removeItem("suspandYn");
      localStorage.removeItem("popYn");
    },
    listReaded(order) {
      document.querySelector(".tabs__header").childNodes[order].click();
    },
    initAuthInfo() {
      //console.log("앱헤더에서 부를 것");
      this.$refs.appheader.calledNav("withDraw");
    },
    async callPopWithSidePassword() {
      let pop;
      pop = {
        popType: "CheckPasswordOwn",
        title: "비밀번호 확인",
        content: "현재 비밀번호를 입력해주세요.",
        immediatly: true,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/join";
@import "@/assets/css/sideContext";
@import "@/assets/scss/Draw";
</style>
